<template>
  <span class="d-flex pl-0 ml-0 justify-space-between">
    <v-list-item v-if="openCatalogUpdateInput" class="flex-grow-1 flex-shrink-1 pt-0">
         <v-text-field v-model="item.title" :error-messages="catalogUpdateErrors.title"
                       class="flex-grow-1 flex-shrink-1 pt-0">
          <v-btn icon slot="append" x-small @click="updateCatalog()">
            <v-icon color="green">mdi-check</v-icon>
          </v-btn>
         </v-text-field>
    </v-list-item>

    <v-list-group
      v-else
      :group="group"
      :prepend-icon="item.icon"
      :sub-group="subGroup"
      :append-icon="'mdi-menu-down'"
      :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'"
      class="flex-grow-1 flex-shrink-1"
    >
      <template v-slot:activator>
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />

        <v-list-item-avatar
          v-else-if="item.avatar"
          class="align-self-center"
          color="white"
          contain
        >
          <v-img src="https://demos.creative-tim.com/vuetify-material-dashboard/favicon.ico"/>
        </v-list-item-avatar>

        <v-icon class="catalog-drag mr-3" small style="opacity: 0.6">mdi-drag</v-icon>
        <v-list-item-title v-text="item.title"/>
      </template>

      <draggable
        handle=".category-drag"
        :scroll-sensitivity="250"
        :fallback-tolerance="1"
        :force-fallback="true"
        v-model="children"
        @end="updateCategoriesOrder"
      >
    <transition-group>

      <template v-for="(child, i) in children">

        <base-item-sub-group
          v-if="child.children"
          :key="`sub-group-${i}`"
          :item="child"
        />

        <base-item
          v-else
          :key="child.id"
          :item="child"
          text
        />
      </template>
    </transition-group>

      </draggable>


      <div class="pb-3 pr-4 pl-4">
        <v-text-field placeholder="Додати категорію" v-model="newCategory.title"
                      :error-messages="categoryStoreErrors.title">
          <v-btn icon slot="append" small @click="storeCategory()">
            <v-icon color="blue">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-text-field>
      </div>
    </v-list-group>

  <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="pa-0 ma-0"
          color="white"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list style="opacity: 0.95; justify-content: center">
        <v-list-item class="px-0">
          <v-btn text @click="openCatalogUpdateInput = !openCatalogUpdateInput">
            Редагувати
            <v-icon x-small class="pl-2">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item>
          <v-list-item class="px-0">
            <v-btn text color="red" @click="deleteCatalog">
              Видалити
              <v-icon x-small class="px-2">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import category from "../../services/category";
import catalog from "../../services/catalog";
// Utilities
import kebabCase from 'lodash/kebabCase'
import {mapState} from 'vuex'
import draggable from "vuedraggable";

export default {
  name: 'ItemGroup',
  components: {draggable},

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['barColor']),
    children: {
      get() {
        this.trigger
        if (this.item.children) {
          return this.item.children.map(item => ({
            ...item,
            to: !item.to ? undefined : `${this.item.group}/${item.to}`,
          }))
        }
        return [];
      },
      set(newChildren) {
        this.item.children = newChildren
        this.trigger += 1
      }
    },

    computedText() {
      if (!this.item || !this.item.title) return ''

      let text = ''

      this.item.title.split(' ').forEach(val => {
        text += val.substring(0, 1)
      })

      return text
    },
    group() {
      if (this.item.children) {
        return this.genGroup(this.item.children)
      }

      return undefined;
    },
  },

  data() {
    return {
      newCategory: {
        title: '',
        catalog_id: this.item.id
      },

      categoryStoreErrors: {
        title: []
      },

      catalogUpdateErrors: {
        title: []
      },

      openCatalogUpdateInput: false,

      trigger: 0
    }
  },

  methods: {
    genGroup(children) {
      return children
        .filter(item => item.to)
        .map(item => {
          const parent = item.group || this.item.group
          let group = `${parent}/${kebabCase(item.to)}`

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`
          }

          return group
        }).join('|')
    },

    storeCategory() {
      category.store(this.newCategory)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) this.categoryStoreErrors = err.data.errors;
        })
    },

    updateCatalog() {
      catalog.update(this.item)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) this.catalogUpdateErrors = err.data.errors;
        })
    },

    updateCategoriesOrder(e) {
      category.updateOrder(this.children)
    },

    deleteCatalog() {
      catalog.delete(this.item.id)
        .then((res) => {
          if (res) this.$router.go(0);
        })
    }
  },
}
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
