import axios from "axios";

const catalog = {
  get: async function() {
    try {
      return await axios.get('/api/dashboard/catalog');

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  store: async function (data) {
    try {
      return await axios.post('/api/dashboard/catalog', data);
    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  update: async function (data) {
    try {
      await axios.put(`/api/dashboard/catalog/${data.id}`, data);
      return true;
    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  updateOrder: async function (data) {
    try {
      await axios.put(`/api/dashboard/catalog/order`, {catalogs: data});
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  delete: async function (id) {
    try {
      await axios.delete(`/api/dashboard/catalog/${id}`);
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },
}

export default catalog;
