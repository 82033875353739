import axios from "axios";

const category = {
  get: async function () {
    try {
      return await axios.get(`/api/dashboard/category`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  show: async function (id, filters) {
    try {
      return await axios.get(`/api/dashboard/category/${id}`, {params:filters});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  store: async function (data) {
    try {
      return await axios.post(`/api/dashboard/category`, data);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  update: async function (data) {
    try {
      await axios.put(`/api/dashboard/category/${data.id}`, data);
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  updateOrder: async function (data) {
    try {
      await axios.put(`/api/dashboard/category/order`, {categories: data});
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  delete: async function (id) {
    try {
      await axios.delete(`/api/dashboard/category/${id}`);
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },
}

export default category;
