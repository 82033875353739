import Vue from 'vue'
import Router from 'vue-router'
import auth from "./services/auth";

Vue.use(Router)

auth.init();

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('@/views/dashboard/LoginView'),
      beforeEnter (to, from, next) {
        if (auth.isAuth()) next('/')
        else next()
      },
      children: [
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/pages/Login/index.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter (to, from, next) {
        if (!auth.isAuth()) next('/login')
        else next()
      },
      children: [
        {
          name: 'Search',
          path: '/',
          component: () => import('@/views/pages/Search/index.vue'),
        },
        {
          name: 'Client',
          path: '/clients',
          component: () => import('@/views/pages/client/index.vue'),
        },
        {
          name: 'ShowCategory',
          path: '/category/:id',
          component: () => import('@/views/pages//category'),
        },
        // Pages
        {
          name: 'User Profile',
          path: '1k',
          component: () => import('@/views/pages//1k'),
        },
        {
          name: 'Notifications',
          path: '2k',
          component: () => import('@/views/pages/2k'),
        },
        {
          name: 'Icons',
          path: '3k',
          component: () => import('@/views/pages/3k'),
        },
        {
          name: 'Typography',
          path: 'building',
          component: () => import('@/views/pages/building'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'area',
          component: () => import('@/views/pages/area'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        }
      ],
    },
  ],
})
