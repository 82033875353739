<template>
    <span class="d-flex pl-0 ml-0 justify-space-between">

      <v-list-item v-if="openCategoryUpdateInput" class="flex-grow-1 flex-shrink-1 pt-0">
         <v-text-field v-model="item.title" :error-messages="categoryUpdateErrors.title" class="flex-grow-1 flex-shrink-1 pt-0">
          <v-btn icon slot="append" x-small @click="updateCategory()">
            <v-icon color="green">mdi-check</v-icon>
          </v-btn>
         </v-text-field>
    </v-list-item>

      <v-list-item
        v-else
        :href="href"
        :rel="href && href !== '#' ? 'noopener' : undefined"
        :to="href"
        :active-class="`primary ${!isDark ? 'black' : 'white'}--text`"
      >

        <v-icon class="category-drag" v-if="!item.isBase" small style="opacity: 0.6">mdi-drag</v-icon>

        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />

       <v-list-item-icon v-else-if="item.icon && clientsNewMatches <= 0">
          <v-icon v-text="item.icon"/>
       </v-list-item-icon>

        <v-list-item-content v-if="item.title || item.subtitle" class="pa-0">
          <v-list-item-title v-if="item.isClients && clientsNewMatches > 0">
            <v-badge inline :content="clientsNewMatches" color="red" class="ma-0">
              <v-list-item-icon >
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
              {{item.title}}
            </v-badge>
          </v-list-item-title>

          <v-list-item-title v-else v-text="item.title"/>

          <v-list-item-subtitle v-text="item.subtitle"/>
        </v-list-item-content>
      </v-list-item>

      <v-menu offset-y v-if="!item.isBase">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="pa-0 ma-0"
          color="white"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list style="opacity: 0.95; justify-content: center">
        <v-list-item class="px-0">
          <v-btn text @click="openCategoryUpdateInput = !openCategoryUpdateInput">
            Редагувати
            <v-icon x-small class="pl-2">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item>
          <v-list-item class="px-0">
            <v-btn text color="red" @click="deleteCategory">
              Видалити
              <v-icon x-small class="px-2">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item>
      </v-list>
    </v-menu>
    </span>
</template>

<script>
  import category from "../../services/category";
  import Themeable from 'vuetify/lib/mixins/themeable'
  import client from "../../services/client";

  export default {
    name: 'Item',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          subtitle: undefined,
          title: undefined,
          to: undefined,
          isBase: false
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      computedText () {
        if (!this.item || !this.item.title) return ''

        let text = ''

        this.item.title.split(' ').forEach(val => {
          text += val.substring(0, 1)
        })

      return text
    },
    href() {
      return this.item.id ? {name: 'ShowCategory', params: {id: this.item.id}} : this.item.to
    },
  },

  data() {
    return {
      categoryUpdateErrors: {
        title: []
      },

      openCategoryUpdateInput: false,

      clientsNewMatches: 0
    }
  },

    mounted() {
      if(this.item.isClients) {
        this.fetchNewMatches()
      }
    },

    methods: {
      updateCategory() {
        category.update(this.item)
          .then((res) => {
            if (res) this.$router.go(0);
          })
          .catch((err) => {
            if (err.status === 422) this.categoryUpdateErrors = err.data.errors;
          })
      },

      deleteCategory() {
        category.delete(this.item.id)
          .then((res) => {
            if (res) this.$router.go(0);
          })
      },

      fetchNewMatches() {
        client.getNewMatches()
          .then((res) => {
            if(res) this.clientsNewMatches = res.data.new_matches_count
          })
      }
    }
}
</script>
