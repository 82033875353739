import axios from "axios";

const client = {
  getNewMatches: async function () {
    try {
      return await axios.get(`/api/dashboard/client/matches`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  get: async function () {
    try {
      return await axios.get(`/api/dashboard/client`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  show: async function (id) {
    try {
      return await axios.get(`/api/dashboard/client/${id}`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  store: async function (data) {
    try {
      return await axios.post(`/api/dashboard/client`, data);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  update: async function (data) {
    try {
      return await axios.put(`/api/dashboard/client/${data.id}`, data);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  delete: async function (id) {
    try {
      await axios.delete(`/api/dashboard/client/${id}`);
      return true;

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },
}

export default client;
