import axios from "axios";

const auth = {
  init: function() {
    const token = this.isAuth();
    if (token) {
      axios.defaults.headers.common = { 'Authorization': `Bearer ${token}`};
    }
  },

  isAuth: function () {
    return localStorage.getItem('access_token');
  },

  login: async function (data) {
    try {
      const response = await axios.post('/api/login', data);

      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('user', JSON.stringify(response.data.user));

      axios.defaults.headers.common = { 'Authorization': `Bearer ${response.data.access_token}` };

      return true;
    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  logout: function () {
    return axios.post(`/api/logout`)
      .catch(err => {return Promise.reject(err.response)})
      .finally(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        axios.defaults.headers.common = { 'Authorization': '' };
        return true;
      });
  }
}

export default auth;
